import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
  _id: string;
  digitalPicture: string;
  email: string;
  phoneNumber: string;
  username: string;
  permission: string;
}

interface InitialState {
  _id: string;
  digitalPicture: string | null;
  email: string;
  phoneNumber: string;
  username: string;
  isLoggedIn: boolean;
  permission: string;
}
const initialState: InitialState = {
  _id: "",
  digitalPicture: null,
  email: "",
  phoneNumber: "",
  username: "",
  isLoggedIn: false,
  permission: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedInUser: (state, action: PayloadAction<User>) => {
      const {
        username,
        email,
        digitalPicture,
        phoneNumber,
        permission,
        _id,
      } = action.payload;
      state.isLoggedIn = localStorage.getItem("accesstoken") ? true : false;
      state.username = username;
      state.email = email;
      state.digitalPicture = digitalPicture;
      state.phoneNumber = phoneNumber;
      state.permission = permission;
      state._id = _id;
    },

    logoutUser() {},

    setProfileUrl: (state, action: PayloadAction<string>) => {
      state.digitalPicture = action.payload;
    },
  },
});

export const { setLoggedInUser, logoutUser, setProfileUrl } = userSlice.actions;

export default userSlice.reducer;
