import * as React from "react";
import AvatarGroup from "@mui/material/AvatarGroup";
import CustomAvatar from "./CustomAvatar";
import { Box } from "@mui/material";
import formatCount from "utils/format.count";
import { useTheme } from "@mui/material/styles";

interface Props {
  users: {
    _id: string;
    username: string;
    digitalPicture: string;
  }[];
  userCount: number;
}
const CustomAvatarGroup = ({ users, userCount }: Props) => {
  const height = "40px";
  const width = "40px";
  const theme = useTheme();
  return (
    <>
      <AvatarGroup
        max={10}
        variant="circular"
        spacing="small"
        sx={{ flexDirection: "row" }}
      >
        {users &&
          users.map((item) => (
            <CustomAvatar
              key={item._id}
              username={item.username}
              digitalPicture={item.digitalPicture}
              height={height}
              width={width}
            />
          ))}
      </AvatarGroup>
      {userCount > 3 && (
        <Box
          sx={{
            height: "44px",
            width: "44px",
            fontSize: "12px",
            borderRadius: "50%",
            display: "flex",
            zIndex: "9",
            border: `2px solid ${theme.palette.secondary.main}`,
            background: theme.palette.secondary[200],
            color: theme.palette.common.white,
            marginRight: "-14px",
          }}
          justifyContent="center"
          alignItems="center"
        >
          +{formatCount(userCount - 3)}
        </Box>
      )}
    </>
  );
};

export default CustomAvatarGroup;
