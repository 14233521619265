import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateProps {
  page: number;
  limit: number;
}

const initialState: InitialStateProps = {
  page: 1,
  limit: 10,
};
export const adminSlice = createSlice({
  name: "admin",
  initialState,

  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setPage } = adminSlice.actions;

export default adminSlice.reducer;
