import Avatar from "@mui/material/Avatar";
import splitUsername from "utils/split.username";
import { useTheme } from "@mui/material/styles";

interface Props {
  username: string;
  digitalPicture?: string;
  height: string;
  width: string;
}

function stringAvatar(name: string) {
  return {
    children: name,
  };
}

const CustomAvatar = ({ username, digitalPicture, height, width }: Props) => {
  const theme = useTheme();
  if (digitalPicture) {
    return (
      <Avatar
        alt={username}
        src={digitalPicture}
        sx={{
          width,
          height,
        }}
      />
    );
  }

  return (
    <Avatar
      {...stringAvatar(splitUsername(username).toUpperCase())}
      sx={{
        width,
        height,
        color: `${theme.palette.common.white}`,
      }}
    />
  );
};

export default CustomAvatar;
