import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyManagementItem, SurveyResultItem } from "views/survey-management/survey-management.types";
import { setReload } from "./trendingCompanySlice";
import { Meta } from "views/investors/InvestorList/InvestorTable";



interface Sort {
    field: string | null;
    order: string | null;
}

interface InitialState {
    page: number;
    limit: number;
    searchText: string | null;
    sort: Sort;
    filterCondition: string | null;
    surveyList: SurveyManagementItem[];
    selectedTab: string
    activeTabCount: number;
    inactiveTabCount: number,
    allTabCount: number,
    archiveTabCount: number,
    totalItems: number,
    selectedSurvey: SurveyManagementItem | null,
    openCreateModal: boolean,
    reload: boolean,
    meta: Meta | null;
    surveyResultListPage: number,
    surveyResultListLimit: number,
    surveyDetailModalOpen: boolean,
    surveyHistoryDetailModalOpen: boolean,
    previousSelectedSurvey: SurveyManagementItem | null
}

const initialState: InitialState = {
    selectedTab: 'all',
    activeTabCount: 0,
    inactiveTabCount: 0,
    allTabCount: 0,
    archiveTabCount: 0,
    page: 1,
    limit: 10,
    searchText: null,
    sort: {
        field: null,
        order: null,
    },
    filterCondition: "all",
    selectedSurvey: null,
    surveyList: [],
    totalItems: 0,
    openCreateModal: false,
    reload: false,
    meta: null,
    surveyResultListPage: 1,
    surveyResultListLimit: 10,
    surveyDetailModalOpen: false,
    previousSelectedSurvey: null,
    surveyHistoryDetailModalOpen: false
};

export const surveyManagement = createSlice({
    name: "surveyManagement",
    initialState,
    reducers: {
        setActiveTabCount: (state, action: PayloadAction<number>) => {
            state.activeTabCount = action.payload;
        },
        setInActiveTabCount: (state, action: PayloadAction<number>) => {
            state.inactiveTabCount = action.payload;
        },
        setSelectedTab: (state, action: PayloadAction<string>) => {
            state.selectedTab = action.payload;
        },
        setAllTabCount: (state, action: PayloadAction<number>) => {
            state.allTabCount = action.payload;
        },
        setSelectedSurvey: (state, action: PayloadAction<SurveyManagementItem | null>) => {
            state.selectedSurvey = action.payload;
        },
        setPreviousSelectedSurvey: (state, action: PayloadAction<SurveyManagementItem | null>) => {
            state.previousSelectedSurvey = action.payload;
        },
        setArchiveTabCount: (state, action: PayloadAction<number>) => {
            state.archiveTabCount = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },

        setLimit: (state, action: PayloadAction<number>) => {
            state.limit = action.payload;
        },
        setOpenCreateModal: (state, action: PayloadAction<boolean>) => {
            state.openCreateModal = action.payload;
        },
        setReloadSurvey: (state, action: PayloadAction<boolean>) => {
            state.reload = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string>) => {
            state.page = 1;
            state.searchText = action.payload;
        },

        setSurveyList: (state, action: PayloadAction<SurveyManagementItem[]>) => {
            state.surveyList = action.payload;
        },

        setFilterCondition: (state, action: PayloadAction<string>) => {
            state.filterCondition = action.payload;
        },
        setTotalItems: (state, action: PayloadAction<number>) => {
            state.totalItems = action.payload;
        },
        setSort: (state, action: PayloadAction<Sort>) => {
            state.sort = action.payload;
        },
        setMeta: (state, action: PayloadAction<Meta | null>) => {
            state.meta = action.payload;
        },
        setSurveyResultListPage: (state, action: PayloadAction<number>) => {
            state.surveyResultListPage = action.payload;
        },
        setSurveyResultListLimit: (state, action: PayloadAction<number>) => {
            state.surveyResultListLimit = action.payload;
        },
        setSurveyDetailModalOpen: (state, action: PayloadAction<boolean>) => {
            state.surveyDetailModalOpen = action.payload
        },
        setSurveyDetailHistoryModalOpen: (state, action: PayloadAction<boolean>) => {
            state.surveyHistoryDetailModalOpen = action.payload
        },
        resetSurvey: () => initialState,
    },
});

export const {
    resetSurvey,
    setSurveyList,
    setFilterCondition,
    setLimit,
    setPage,
    setSearchText,
    setSort,
    setSelectedTab,
    setActiveTabCount,
    setAllTabCount,
    setArchiveTabCount,
    setInActiveTabCount,
    setTotalItems,
    setSelectedSurvey,
    setOpenCreateModal,
    setReloadSurvey,
    setMeta,
    setSurveyResultListLimit,
    setSurveyResultListPage,
    setSurveyDetailModalOpen,
    setSurveyDetailHistoryModalOpen,
    setPreviousSelectedSurvey
} = surveyManagement.actions;

export const selectSurveyManagement = (state: any) => state.surveyManagement;

export default surveyManagement.reducer;
