import {
  EDIT_NON_CUSTOMER_ROUTE,
  NON_CUSTOMER_ROUTE,
  QUESITON_PROMPT_ROUTE,
  SURVEY_MANAGEMENT,
  TRENDING_COMPANIES,
} from "./../constants/routes";
import {
  ACCOUNT_PROFILE_ROUTE,
  ADD_USER_ROUTE,
  ADMIN_LIST_ROUTE,
  COMPANY_ROUTE,
  COMPANY_USERS_ROUTE,
  CONTACT_DETAIL_ROUTE,
  CONTACT_FORM_ROUTE,
  DASHBOARD_ROUTE,
  EDIT_COMPANY_ROUTE,
  EDIT_COMPANY_USER_ROUTE,
  EDIT_INVESTOR_ROUTE,
  EDIT_USER_ROUTE,
  EMAILS_ROUTE,
  INVESTOR_ROUTE,
  OTHER_REPORT_DETAIL_ROUTE,
  OTHER_REPORT_FORM_ROUTE,
  PAGE_MANAGEMENT_ROUTE,
  REPORT_DETAIL_ROUTE,
  REPORT_FORM_ROUTE,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_LIST_ROUTE,
} from "constants/routes";
import { useLocation } from "react-router-dom";

const useBreadCrumbTitles = () => {
  const { pathname: currentRoute } = useLocation();

  let titleList: any = [];

  // dashboard
  if (currentRoute === DASHBOARD_ROUTE) titleList = ["Dashboard"];

  // account profile
  if (currentRoute === ACCOUNT_PROFILE_ROUTE) titleList = ["Account settings"];

  // admin
  if (currentRoute === ADMIN_LIST_ROUTE) titleList = ["Admin users"];
  if (currentRoute === ADD_USER_ROUTE) titleList = ["Admin users", "Add user"];
  if (currentRoute.includes(EDIT_USER_ROUTE))
    titleList = ["Admin users", "Edit user"];

  // page management
  if (currentRoute === PAGE_MANAGEMENT_ROUTE) titleList = ["Manage pages"];
  if (currentRoute.includes(`${PAGE_MANAGEMENT_ROUTE}/`))
    titleList = ["Manage pages", "Edit page"];

  //email
  if (currentRoute === EMAILS_ROUTE) titleList = ["Emails"];
  if (currentRoute.includes(`${EMAILS_ROUTE}/`))
    titleList = ["Emails", "Edit email"];

  // companies
  if (currentRoute === COMPANY_ROUTE) titleList = ["Companies"];
  if (currentRoute === EDIT_COMPANY_ROUTE)
    titleList = ["Companies", "Company details"];

  if (currentRoute === EDIT_NON_CUSTOMER_ROUTE) {
    return (titleList = ["Non-customers", "Non-Customer details"]);
  }

  // company users
  if (currentRoute === COMPANY_USERS_ROUTE) titleList = ["Company users"];
  if (currentRoute === EDIT_COMPANY_USER_ROUTE)
    titleList = ["Company users", "User details"];

  // investors
  if (currentRoute === INVESTOR_ROUTE) titleList = ["Investors"];
  if (currentRoute === EDIT_INVESTOR_ROUTE)
    titleList = ["Investors", "Investor details"];

  if (currentRoute === QUESITON_PROMPT_ROUTE) titleList = ["Question Prompt"];
  if (currentRoute === SURVEY_MANAGEMENT) titleList = ["Survey Management"];

  // report
  if (currentRoute === REPORT_FORM_ROUTE) titleList = ["Issue reports"];
  if (currentRoute === REPORT_DETAIL_ROUTE)
    titleList = ["Report forms", "Details"];

  if (currentRoute === OTHER_REPORT_FORM_ROUTE)
    titleList = ["Company requests"];
  if (currentRoute === OTHER_REPORT_DETAIL_ROUTE)
    titleList = ["Company requests", "Details"];

  //contact
  if (currentRoute === CONTACT_FORM_ROUTE) titleList = ["Contact"];
  if (currentRoute === CONTACT_DETAIL_ROUTE)
    titleList = ["Contact forms", "Details"];

  // subscription
  if (currentRoute === SUBSCRIPTION_LIST_ROUTE)
    titleList = ["Subscription management"];
  if (currentRoute === SUBSCRIPTION_DETAILS)
    titleList = ["Subscription management", "Subscription Details"];
  if (currentRoute.includes(NON_CUSTOMER_ROUTE)) titleList = ["Non Customers"];
  if (currentRoute.includes(TRENDING_COMPANIES))
    titleList = ["Trending Companies"];
  return titleList;
};

export default useBreadCrumbTitles;
