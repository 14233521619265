import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Sort {
  field: string | null;
  order: string | null;
}
export interface SelectedItems {
  _id: string;
}
interface InitialState {
  searchText: string;
  page: number;
  limit: number;
  sort: {
    field: string | null;
    order: string | null;
  };
  selectedItems: SelectedItems[];
  selectAll: boolean;
}

const initialState: InitialState = {
  searchText: "",
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },
  selectedItems: [],
  selectAll: false,
};
export const otherReportSlice = createSlice({
  name: "otherReport",
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },
    setSelectedItems: (state, action: any) => {
      if (action.action === "remove") {
        const index = state.selectedItems.findIndex((item) => {
          return item === action.selectedItems;
        });
        if (index >= 0) {
          state.selectedItems.splice(index, 1);
        }
      } else if (action.action === "empty") {
        state.selectedItems = [];
      } else {
        state.selectedItems.push(action.selectedItems);
      }
    },
    setSelectAll: (state, action: any) => {
      state.selectAll = action.selectAll;
    },
  },
});

export const {
  setSearchText,
  setPage,
  setLimit,
  setSort,
  setSelectedItems,
  setSelectAll,
} = otherReportSlice.actions;

export const selectOtherReport = (state: any) => state.otherReport;

export default otherReportSlice.reducer;
