import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import PageManagement from "views/page-management";
import EditPage from "views/page-management/EditPage";
import Error from "views/pages/maintenance/Error";
import {
  ACCOUNT_PROFILE_ROUTE,
  ADMIN_LIST_ROUTE,
  COMPANY_ROUTE,
  COMPANY_USERS_ROUTE,
  CONTACT_DETAIL_ROUTE,
  CONTACT_FORM_ROUTE,
  DASHBOARD_ROUTE,
  EDIT_COMPANY_ROUTE,
  EDIT_COMPANY_USER_ROUTE,
  EDIT_INVESTOR_ROUTE,
  EDIT_NON_CUSTOMER_ROUTE,
  EDIT_USER_ROUTE,
  EMAILS_ROUTE,
  ERROR_ROUTE,
  INVESTOR_ROUTE,
  NON_CUSTOMER_ROUTE,
  OTHER_REPORT_DETAIL_ROUTE,
  OTHER_REPORT_FORM_ROUTE,
  PAGE_MANAGEMENT_ROUTE,
  QUESITON_PROMPT_ROUTE,
  REPORT_DETAIL_ROUTE,
  REPORT_FORM_ROUTE,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_LIST_ROUTE,
  SURVEY_MANAGEMENT,
  TRENDING_COMPANIES,
} from "constants/routes";

import EditEmailTemplate from "views/emails/EditEmailTemplate";

import OtherReportDetail from "views/other-reports/OtherReportDetail";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// application - user social & account profile routing

const AppUserAccountProfile = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile2"))
);

const AdminList = Loadable(lazy(() => import("views/admin/AdminList")));

const CompanyUserList = Loadable(lazy(() => import("views/company-users")));

const TrendingCompanyList = Loadable(
  lazy(() => import("views/trending-company"))
);

const NonCustomerList = Loadable(lazy(() => import("views/non-customers")));

const EditCompanyUser = Loadable(
  lazy(() => import("views/company-users/EditCompanyUser"))
);
const Emails = Loadable(lazy(() => import("views/emails")));

const InvestorList = Loadable(
  lazy(() => import("views/investors/InvestorList"))
);

const EditInvestor = Loadable(
  lazy(() => import("views/investors/EditInvestor"))
);

const EditAdmin = Loadable(lazy(() => import("views/admin/EditAdmin")));

const Company = Loadable(lazy(() => import("views/companies")));

const EditCompany = Loadable(lazy(() => import("views/companies/EditCompany")));

const EditNonCustomer = Loadable(
  lazy(() => import("views/non-customers/EditNonCustomer"))
);

const ReportList = Loadable(lazy(() => import("views/reports")));
const OtherReportList = Loadable(lazy(() => import("views/other-reports")));

const ReportDetail = Loadable(lazy(() => import("views/reports/ReportDetail")));

const ContactUs = Loadable(lazy(() => import("views/contact-us")));

const ContactDetail = Loadable(
  lazy(() => import("views/contact-us/ContactDetail"))
);

const SubscriptionList = Loadable(
  lazy(() => import("views/subscription/SubscriptionList"))
);

const SubscriptionPlan = Loadable(
  lazy(() => import("views/subscription/SubscriptionDetails"))
);

const QuestionPrompt = Loadable(lazy(() => import("views/question-prompt")));
const SurveyManagement = Loadable(
  lazy(() => import("views/survey-management"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: ACCOUNT_PROFILE_ROUTE,
      element: <AppUserAccountProfile />,
    },
    {
      path: PAGE_MANAGEMENT_ROUTE,
      element: <PageManagement />,
    },
    {
      path: `${PAGE_MANAGEMENT_ROUTE}/:slug`,
      element: <EditPage />,
    },
    {
      path: ADMIN_LIST_ROUTE,
      element: <AdminList />,
    },
    {
      path: DASHBOARD_ROUTE,
      element: <DashboardDefault />,
    },

    {
      path: ERROR_ROUTE,
      element: <Error />,
    },
    {
      path: COMPANY_USERS_ROUTE,
      element: <CompanyUserList />,
    },
    {
      path: EDIT_COMPANY_USER_ROUTE,
      element: <EditCompanyUser />,
    },
    {
      path: EMAILS_ROUTE,
      element: <Emails />,
    },
    {
      path: `${EMAILS_ROUTE}/:slug`,
      element: <EditEmailTemplate />,
    },
    {
      path: INVESTOR_ROUTE,
      element: <InvestorList />,
    },
    {
      path: EDIT_INVESTOR_ROUTE,
      element: <EditInvestor />,
    },
    {
      path: QUESITON_PROMPT_ROUTE,
      element: <QuestionPrompt />,
    },
    {
      path: SURVEY_MANAGEMENT,
      element: <SurveyManagement />,
    },

    { path: `${EDIT_USER_ROUTE}/:id`, element: <EditAdmin /> },

    { path: COMPANY_ROUTE, element: <Company /> },

    { path: EDIT_COMPANY_ROUTE, element: <EditCompany /> },
    { path: EDIT_NON_CUSTOMER_ROUTE, element: <EditNonCustomer /> },

    { path: REPORT_FORM_ROUTE, element: <ReportList /> },
    { path: REPORT_DETAIL_ROUTE, element: <ReportDetail /> },

    // { path: OTHER_REPORT_FORM_ROUTE, element: <OtherReportList /> },
    // { path: OTHER_REPORT_DETAIL_ROUTE, element: <OtherReportDetail /> },
    {
      path: CONTACT_FORM_ROUTE,
      element: <ContactUs />,
    },
    {
      path: CONTACT_DETAIL_ROUTE,
      element: <ContactDetail />,
    },
    {
      path: SUBSCRIPTION_LIST_ROUTE,
      element: <SubscriptionList />,
    },
    {
      path: SUBSCRIPTION_DETAILS,
      element: <SubscriptionPlan />,
    },
    {
      path: NON_CUSTOMER_ROUTE,
      element: <NonCustomerList />,
    },
    {
      path: TRENDING_COMPANIES,
      element: <TrendingCompanyList />,
    },
  ],
};

export default MainRoutes;
