// third-party
import { FormattedMessage } from "react-intl";

// ui
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DashboardIcon from "ui-component/Icons/DashboardIcon";

import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";

//project imports
import {
  ADMIN_LIST_ROUTE,
  DASHBOARD_ROUTE,
  EMAILS_ROUTE,
  SUBSCRIPTION_LIST_ROUTE,
} from "constants/routes";

const dashboard = {
  id: "dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: DASHBOARD_ROUTE,
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "admin-users",
      title: <FormattedMessage id="admin users" />,
      type: "item",
      url: ADMIN_LIST_ROUTE,
      icon: StorefrontOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "subscription-management",
      title: <FormattedMessage id="subscription-management" />,
      type: "item",
      url: SUBSCRIPTION_LIST_ROUTE,
      icon: ArticleOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "email-template-management",
      title: <FormattedMessage id="email-template-management" />,
      type: "item",
      url: EMAILS_ROUTE,
      icon: ArticleOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
