// third-party
import { FormattedMessage } from "react-intl";

import CompanyUsersIcon from "ui-component/Icons/CompanyUsersIcon";
import PageIcon from "ui-component/Icons/PageIcon";

import {
  INVESTOR_ROUTE,
  PAGE_MANAGEMENT_ROUTE,
  QUESITON_PROMPT_ROUTE,
  SURVEY_MANAGEMENT,
} from "constants/routes";

// ==============================|| WIDGET MENU ITEMS ||============================== //

const mobileApp = {
  id: "mobileApp",
  title: <FormattedMessage id="mobileApp" />,
  type: "group",
  children: [
    {
      id: "page-management",
      title: <FormattedMessage id="page-management" />,
      type: "item",
      url: PAGE_MANAGEMENT_ROUTE,
      icon: PageIcon,
      breadcrumbs: false,
    },
    {
      id: "investors",
      title: <FormattedMessage id="investors" />,
      type: "item",
      url: INVESTOR_ROUTE,
      icon: CompanyUsersIcon,
      breadcrumbs: false,
    },
    {
      id: "question-prompt",
      title: "Question Prompt",
      type: "item",
      url: QUESITON_PROMPT_ROUTE,
      icon: PageIcon,
      breadcrumbs: false,
    },
    {
      id: "survey-management",
      title: "Survey management",
      type: "item",
      url: SURVEY_MANAGEMENT,
      icon: PageIcon,
      breadcrumbs: false,
    },
  ],
};

export default mobileApp;
