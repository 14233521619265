import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// material-ui
import { styled, useTheme, Theme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Grid,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
import { drawerWidth, gridSpacing } from "store/constant";
import { SET_MENU } from "store/actions";
import { DefaultRootStateProps } from "types";

// assets
import CustomBreadcrumb from "ui-component/CustomBreadCrumbs";
import useBreadCrumbTitles from "hooks/useBreadCrumbTitles";
import UserProfileCard from "ui-component/UserProfileCard";

import { DASHBOARD_ROUTE } from "constants/routes";

import AdminAndInvestorCard from "ui-component/AdminAndInvestorCard";
import { useAppDispatch } from "store/hooks";
import { logoutUser } from "store/slice/userSlice";

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("lg")]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const titleList = useBreadCrumbTitles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  const { pathname: currentRoute } = useLocation();
  const navigate = useNavigate();

  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: DefaultRootStateProps) => state.customization.opened
  );
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  React.useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  useEffect(() => {
    if (currentRoute === "/") {
      navigate(DASHBOARD_ROUTE);
    }

    const accesstoken = localStorage.getItem("accesstoken");
    const refreshtoken = localStorage.getItem("refreshtoken");

    if (!accesstoken || !refreshtoken) {
      appDispatch(logoutUser());
    }
  }, [currentRoute, appDispatch, navigate]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
        className={
          currentRoute === DASHBOARD_ROUTE ? "dashboard-header" : "inner-header"
        }
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main
        theme={theme}
        open={leftDrawerOpened}
        sx={{ background: `${theme.palette.background.default}`, mr: 0 }}
      >
        <Grid container columnSpacing={gridSpacing}>
          <Grid
            item
            md={currentRoute === DASHBOARD_ROUTE ? 9 : 12}
            sm={currentRoute === DASHBOARD_ROUTE ? 8 : 12}
            xs={12}
            sx={{
              mt: { xs: "92px", lg: "86px", minHeight: "100vh" },
              borderRadius: "12px",
              p: 3,
              background: `${theme.palette.background.paper}`,
            }}
            className={
              currentRoute === DASHBOARD_ROUTE
                ? "dashboard-content"
                : "inner-content"
            }
          >
            <CustomBreadcrumb titleList={titleList} />
            <Outlet />
          </Grid>
          {currentRoute === DASHBOARD_ROUTE && (
            <Grid
              item
              md={3}
              sm={4}
              xs={12}
              sx={{
                background: `${theme.palette.background.default}`,
              }}
            >
              <Grid
                container
                rowSpacing={gridSpacing}
                className="sticky-sidebar"
              >
                <Grid item xs={12}>
                  <UserProfileCard />
                </Grid>

                <AdminAndInvestorCard />
              </Grid>
              <Grid />
            </Grid>
          )}
        </Grid>
      </Main>
    </Box>
  );
};

export default MainLayout;
