import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Sort {
  field: string | null;
  order: string | null;
}

interface InitialState {
  searchText: string;
  page: number;
  limit: number;
  sort: {
    field: string | null;
    order: string | null;
  };
}

const initialState: InitialState = {
  searchText: "",
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },
};
export const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },
  },
});

export const {
  setSort,
  setLimit,
  setPage,
  setSearchText,
} = transactionHistorySlice.actions;

export const selectTransactionHistory = (state: any) =>
  state.transactionHistory;

export default transactionHistorySlice.reducer;
