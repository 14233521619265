import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Sort {
  field: string | null;
  order: string | null;
}

interface Investor {
  _id: string;
  communicationConsent: boolean;
  email: string;
  enquiriesSubmitted: string;
  startDate: string;
  userName: string;
}

interface InitialState {
  investorList: Investor[];
  limit: number;
  mailingList: string | null;
  page: number;
  searchText: string | null;
  sort: Sort;
}

const initialState: InitialState = {
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },
  investorList: [],
  mailingList: "all",
  searchText: null,
};

export const investorSlice = createSlice({
  name: "investor",
  initialState,
  reducers: {
    setSort: (state: any, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },

    setInvestorList: (state: any, action: PayloadAction<Investor[]>) => {
      state.investorList = [...action.payload];
    },

    setMailingList: (state: any, action: PayloadAction<string>) => {
      state.mailingList = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    resetInvestor: () => initialState,
  },
});

export const {
  resetInvestor,
  setInvestorList,
  setLimit,
  setMailingList,
  setPage,
  setSearchText,
  setSort,
} = investorSlice.actions;

export const selectInvestor = (state: any) => state.investor;

export default investorSlice.reducer;
