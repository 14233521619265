// material-ui
import { useTheme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";

// assets

import { useAppSelector } from "store/hooks";
import CustomAvatar from "./CustomAvatar";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_PROFILE_ROUTE } from "constants/routes";
import { Box } from "@mui/system";

const UserProfileCard = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { username, digitalPicture } = useAppSelector((state) => state.user);

  const handleClick = () => {
    navigate(ACCOUNT_PROFILE_ROUTE);
  };

  return (
    <Box
      sx={{
        background: `${theme.palette.secondary.main}`,
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "20px 40px 16px 40px",
      }}
    >
      <Box
        sx={{
          mb: "1rem",
          //border: `1px dashed ${theme.palette.warning.light}`,
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFF8E8FF' stroke-width='1' stroke-dasharray='4%25%2c 4%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
          borderRadius: "100px",
          //borderRadius: "50%",
          padding: "0.4rem",
        }}
      >
        {/* <Badge
          color="success"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          badgeContent={<TickIcon />}
        >
          <CustomAvatar
            username={username}
            digitalPicture={digitalPicture}
            width="102px"
            height="102px"
          />
        </Badge> */}
        <CustomAvatar
          username={username }
          digitalPicture={digitalPicture || ""}
          width="102px"
          height="102px"
        />
      </Box>

      <Typography
        sx={{
          textTransform: "capitalize",
          color: `${theme.palette.common.white}`,
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "19px",
          textAlign: "center",
          mb: ".5rem",
        }}
      >
        {username}
      </Typography>
      {/* <Typography
        sx={{
          color: `${theme.palette.orange.light}`,
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "19px",
          textAlign: "center",
          mb: "1.5rem",
        }}
      >
        Admin manager
      </Typography> */}

      <Button
        color="info"
        variant="contained"
        size="medium"
        sx={{ borderRadius: "4px", width: "100%", mt: "1.5rem" }}
        onClick={handleClick}
      >
        Edit profile
      </Button>
    </Box>
  );
};

export default UserProfileCard;
