import { NavItemType } from "types";
import dashboard from "./dashboard";
import companies from "./companies";
import mobileApp from "./mobileApp";
import feedback from "./feedback";

// ==============================|| MENU-ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, companies, mobileApp, feedback],
};

export default menuItems;
