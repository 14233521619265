import { lazy } from "react";

import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import EditGuard from "utils/route-guard/EditGuard";
import { ADD_USER_ROUTE } from "constants/routes";

const AddAdmin = Loadable(lazy(() => import("views/admin/AddAdmin")));

const EditorRoutes = {
  path: "/",
  element: (
    <EditGuard>
      <MainLayout />
    </EditGuard>
  ),
  children: [
    {
      path: ADD_USER_ROUTE,
      element: <AddAdmin />,
    },
  ],
};

export default EditorRoutes;
