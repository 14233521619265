import { useAppDispatch } from "store/hooks";
import { useQuery } from "@apollo/client";
import { snackbarOpen } from "store/slice/snackbarSlice";
import {
  GET_ADMINS_WITH_PICTURE,
  GET_INVESTORS_WITH_PICTURE,
} from "schemas/query";
import UserWithImage from "./UserWithImage";
import { ADMIN_LIST_ROUTE, INVESTOR_ROUTE } from "constants/routes";
import Loader from "./Loader";

const AdminAndInvestorCard = () => {
  const dispatch = useAppDispatch();

  const { data: adminData, loading: adminLoading } = useQuery(
    GET_ADMINS_WITH_PICTURE,
    {
      onError: (error) => {
        dispatch(
          snackbarOpen({
            open: true,
            severity: "error",
            message: error.message,
          })
        );
      },
    }
  );

  const { data: investorData, loading } = useQuery(GET_INVESTORS_WITH_PICTURE, {
    onError: (error) => {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: error.message,
        })
      );
    },
  });

  if (loading || adminLoading) {
    return <Loader />;
  }
  return (
    <>
      <UserWithImage
        route={ADMIN_LIST_ROUTE}
        {...adminData?.getAdminPictureWithCount}
      />
      <UserWithImage
        route={INVESTOR_ROUTE}
        {...investorData?.getInvestorPictureWithCount}
      />
    </>
  );
};

export default AdminAndInvestorCard;
