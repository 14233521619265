const splitUsername = (username: string) => {
  if (!username) {
    return "AB";
  }
  const splittedName = username.split(" ");
  const firstName = splittedName[0];
  const lastName = splittedName[splittedName.length - 1];

  if (splittedName.length === 1) {
    return firstName.substring(0, 1);
  }

  return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;
};

export default splitUsername;
