// dashboard
export const LOGIN_ROUTE = "/login";
export const DASHBOARD_ROUTE = "/dashboard";
export const ADD_USER_ROUTE = "/admin/add-user";
export const EDIT_USER_ROUTE = "/admin/edit-user";
export const ADMIN_LIST_ROUTE = "/admin";
export const SUBSCRIPTION_LIST_ROUTE = "/subscription";
export const SUBSCRIPTION_DETAILS = "/subscription/details";
export const EMAILS_ROUTE = "/email";
export const ERROR_ROUTE = "/error";

// companies
export const COMPANY_ROUTE = "/company";
export const EDIT_COMPANY_ROUTE = "/company/edit";
export const COMPANY_USERS_ROUTE = "/web-user";
export const EDIT_COMPANY_USER_ROUTE = "/web-user/edit";

//non-customer
export const NON_CUSTOMER_ROUTE = "/non-customers";
export const EDIT_NON_CUSTOMER_ROUTE = "/non-customers/edit";

//trending-companies
export const TRENDING_COMPANIES = "/trending-companies";

// mobile app
export const INVESTOR_ROUTE = "/investor";
export const EDIT_INVESTOR_ROUTE = "/investor/edit";
export const PAGE_MANAGEMENT_ROUTE = "/page-management";
export const QUESITON_PROMPT_ROUTE = "/question-prompt";
export const SURVEY_MANAGEMENT = "/survey-management";

// account setting
export const ACCOUNT_PROFILE_ROUTE = "/user/account-profile";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";

// feedback
export const CONTACT_DETAIL_ROUTE = "/contact/detail";
export const CONTACT_FORM_ROUTE = "/contact";
export const REPORT_DETAIL_ROUTE = "/report/detail";
export const REPORT_FORM_ROUTE = "/reports";
export const OTHER_REPORT_FORM_ROUTE = "/company-requests";
export const OTHER_REPORT_DETAIL_ROUTE = "/company-request/detail";
