const validationMessages = {
  EN: {
    TITLE_REQUIRED: "Page title is required.",
    CONTENT_REQUIRED: "Page content is required.",
    EMAIL_REQUIRED: "Email is required.",
    INVALID_EMAIL: "Invalid email address.",

    // phone
    PHONE_NUMBER_REQUIRED: "Phone number is required.",
    PHONE_MAX_LENGTH: "Phone number must not be longer than 12 characters.",
    PHONE_MIN_LENGTH: "Phone number must be at least 10 characters long.",
    PROVIDE_VALID_PHONE: "Please provide valid phone number.",

    PERMISSION_REQUIRED: "Permission is required.",

    FULL_NAME_REQUIRED: "Full name is required.",
    MIN_USERNAME_CHARACTER: "Must be  at least three characters.",
    FIRST_NAME_REQUIRED: "First name is required.",
    LAST_NAME_REQUIRED: "Last name is required.",
    PROVIDE_VALID_FULL_NAME:
      "Please provide at least first name and last name.",

    MIN_PASSWORD_CHARACTER: "Must be  at least six characters.",
    PASSWORD_REQUIRED: "Password is required.",
    OLD_PASSWORD_REQUIRED: "Current password is required.",
    NEW_PASSWORD_REQUIRED: "New password is required.",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required.",
    PASSWORD_MUST_MATCH: "Password should match.",

    JOB_TITLE_REQUIRED: "Job title is required.",
    COMPANY_NAME_REQUIRED: "Company name is required.",

    // mobile
    MOBILE_MAX_LENGTH: "Mobile number must not be longer than 12 characters.",
    MOBILE_MIN_LENGTH: "Mobile number must be at least 10 characters long.",
    MOBILE_REQUIRED: "Mobile number is required.",
    PROVIDE_VALID_MOBILE: "Please provide valid mobile number.",

    //mail
    TEMPLATE_NAME_REQUIRED: "Template name is required.",
    SUBJECT_LINE_REQUIRED: "Subject line is required.",

    // admin permission
    INVALID_ADMIN_PERMISSION: "Invalid Admin Permission.",

    // edit company
    ABOUT_REQUIRED: "About is required.",
    ADDRESS_LINE_ONE_REQUIRED: "Address line one is required.",
    LEGAL_BUSINESS_NAME_REQUIRED: "Legal business name is required.",
    POST_CODE_REQUIRED: "Post code is required.",
    PROVIDE_VALID_WEBSITE: "Domain couldn't be verified. Not a valid URL.",
    STATE_REQUIRED: "State is required.",
    SUBURB_REQUIRED: "Suburb is required.",
    WEBSITE_REQUIRED: "Website is required.",

    // file
    FILE_TOO_LARGE: "Uploaded file is too large.",

    //Survey
    QUESTION_REQUIRED: "Please provide a question.",
    RESPONSE_REQUIRED: "Please provide a response.",
    QUESTION_MAX: "Please provide a question of 150 characters.",
    RESPONSE_MAX: "Please provide a response of 100 characters.",
    ABN_REQUIRED: "Please provide valid mobile number.",
  },
};
const lang = process.env.SYSTEM_LANGUAGE || "EN";

let MSG: any;

if (lang === "EN") {
  MSG = validationMessages.EN;
}

export default MSG;
