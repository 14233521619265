// third-party
import { FormattedMessage } from "react-intl";

import CompaniesIcon from "./../ui-component/Icons/CompaniesIcon";
import CompanyUsersIcon from "./../ui-component/Icons/CompanyUsersIcon";
import TrendingIcon from "ui-component/Icons/TrendingUpIcon";
import { COMPANY_ROUTE, COMPANY_USERS_ROUTE, NON_CUSTOMER_ROUTE, TRENDING_COMPANIES } from "constants/routes";

// ==============================|| WIDGET MENU ITEMS ||============================== //

const companies = {
  id: "companyList",
  title: <FormattedMessage id="companies" />,
  type: "group",
  children: [
    {
      id: "companyList",
      title: <FormattedMessage id="companies" />,
      type: "item",
      url: COMPANY_ROUTE,
      icon: CompaniesIcon,
      breadcrumbs: false,
    },
    {
      id: "company users",
      title: <FormattedMessage id="company users" />,
      type: "item",
      url: COMPANY_USERS_ROUTE,
      icon: CompanyUsersIcon,
      breadcrumbs: false,
    },
    {
      id: "nonCustomers",
      title: "Non-customers",
      type: "item",
      url: NON_CUSTOMER_ROUTE,
      icon: CompanyUsersIcon,
      breadcrumbs: false,
    },
    {
      id: "trending companies",
      title: "Trending companies",
      type: "item",
      url: TRENDING_COMPANIES,
      icon: TrendingIcon,
      breadcrumbs: false,
    },
  ],
};

export default companies;
