//ui
import { Grid, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

// project imports
import PageList from "./PageList";

const PageManagement = () => {
  return (
    <MainCard
      title={
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={7}>
            <Typography variant="h3">Manage pages</Typography>
          </Grid>
        </Grid>
      }
      content={false}
    >
      <PageList />
    </MainCard>
  );
};

export default PageManagement;
