const formatCount = (count: number) => {
  const billion: number = 1000000000;
  const million: number = 1000000;
  const thousand: number = 1000;

  if (count > billion) return `${(count / thousand).toFixed(1)}K`;

  if (count > million) return `${(count / thousand).toFixed(1)}K`;

  if (count > thousand) return `${(count / thousand).toFixed(1)}K`;

  return count;
};

export default formatCount;
