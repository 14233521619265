import styled from "styled-components";
import * as palette from "constants/color";

export const ProfileSectionBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .profile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
`;

export const UserName = styled.h3`
  font-size: 1.125rem;
  padding: 0 .813rem;
  color: ${palette.Black};
  font-weight: 500;

  span {
    text-decoration: underline;
    font-weight: 600;
  }
`;
