import React from "react";

function Icon({ width = "21px", height = "20px", fill = "#3d4853" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        fill={fill}
        d="M2.992 0A2.013 2.013 0 00.996 2v7c0 1.093.905 2 1.996 2h4.991a2.013 2.013 0 001.996-2V2c0-1.093-.905-2-1.996-2h-4.99zm10.98 0a2.013 2.013 0 00-1.996 2v3c0 1.093.905 2 1.996 2h4.99a2.013 2.013 0 001.997-2V2c0-1.093-.906-2-1.997-2h-4.99zM2.992 2h4.991v7h-4.99V2zm10.98 0h4.99v3h-4.99V2zm0 7a2.013 2.013 0 00-1.996 2v7c0 1.093.905 2 1.996 2h4.99a2.013 2.013 0 001.997-2v-7c0-1.093-.906-2-1.997-2h-4.99zm0 2h4.99v7h-4.99v-7zm-10.98 2a2.013 2.013 0 00-1.996 2v3c0 1.093.905 2 1.996 2h4.991a2.013 2.013 0 001.996-2v-3c0-1.093-.905-2-1.996-2h-4.99zm0 2h4.991v3h-4.99v-3z"
      ></path>
    </svg>
  );
}

export default Icon;
