import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE } from "constants/routes";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3"))
);

const AuthForgotPassword = Loadable(
  lazy(
    () => import("views/pages/authentication/authentication3/ForgotPassword3")
  )
);

const ResetPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/ResetPassword"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: LOGIN_ROUTE,
      element: <AuthLogin />,
    },

    {
      path: `${FORGOT_PASSWORD_ROUTE}/:token`,
      element: <ResetPassword />,
    },
    {
      path: FORGOT_PASSWORD_ROUTE,
      element: <AuthForgotPassword />,
    },
  ],
};

export default LoginRoutes;
