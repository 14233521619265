import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NonCustomerItem } from "views/companies/company.types";

export interface Sort {
  field: string | null;
  order: string | null;
}

interface InitialState {
  searchText: string;
  page: number;
  limit: number;
  sort: {
    field: string | null;
    order: string | null;
  };
  nonCustomerFilter: string | null;
  nonCustomerList: NonCustomerItem[];
  totalItems: number;
}

const initialState: InitialState = {
  searchText: "",
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },
  nonCustomerFilter: null,
  nonCustomerList: [],
  totalItems: 0,
};

export const nonCustomerSlice = createSlice({
  name: "nonCustomer",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setNonCustomerFilter: (state, action: PayloadAction<string>) => {
      state.nonCustomerFilter = action.payload;
    },

    setNonCustomerList: (state, action: PayloadAction<NonCustomerItem[]>) => {
      state.nonCustomerList = action.payload;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    resetNonCustomer: () => initialState,
  },
});
export const selectNonCustomer = (state: any) => state.nonCustomer;
export const {
  setSort,
  setLimit,
  setPage,
  setSearchText,
  setNonCustomerFilter,
  setNonCustomerList,
  resetNonCustomer,
  setTotalItems
} = nonCustomerSlice.actions;

export default nonCustomerSlice.reducer;
