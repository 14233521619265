import * as React from "react";

import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { AlertColor } from "@mui/material";

import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Toast() {
  const { open, message, vertical, horizontal, severity } = useAppSelector(
    (state) => state.snackbar
  );

  const [variant, setVariant] = React.useState<AlertColor>("success");
  const [anchorOrigin, setAnchorOrigin] = React.useState<SnackbarOrigin>({
    horizontal: "center",
    vertical: "bottom",
  });

  const dispatch = useAppDispatch();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(snackbarOpen({ open: false }));
  };

  React.useEffect(() => {
    severity === "error" && setVariant("error");
    severity === "success" && setVariant("success");
    severity === "warning" && setVariant("warning");
    severity === "info" && setVariant("info");

    vertical === "bottom" &&
      setAnchorOrigin((prevState) => {
        return { ...prevState, vertical: "bottom" };
      });

    horizontal === "center" &&
      setAnchorOrigin((prevState) => {
        return { ...prevState, horizontal: "center" };
      });
  }, [severity, vertical, horizontal]);
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert onClose={handleClose} severity={variant} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
