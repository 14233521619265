import { useAppSelector } from "store/hooks";

import CONST from "constants/generalConstants";

const useEditorPermission = () => {
  const { permission } = useAppSelector((state) => state.user);

  if (permission === CONST.VIEWER) return false;

  if (permission === CONST.EDITOR || permission === CONST.SUPER) return true;
};

export default useEditorPermission;
