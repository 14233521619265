// third-party
import { FormattedMessage } from "react-intl";

import CompanyUsersIcon from "ui-component/Icons/CompanyUsersIcon";
import { CONTACT_FORM_ROUTE, OTHER_REPORT_FORM_ROUTE, REPORT_FORM_ROUTE } from "constants/routes";

// ==============================|| WIDGET MENU ITEMS ||============================== //

const feedback = {
  id: "feedback",
  title: <FormattedMessage id="feedback" />,
  type: "group",
  children: [
    {
      id: "contact",
      title: "Contact",
      type: "item",
      url: CONTACT_FORM_ROUTE,
      icon: CompanyUsersIcon,
      breadcrumbs: false
    },
    {
      id: "report",
      title: "Issue reports",
      type: "item",
      url: REPORT_FORM_ROUTE,
      icon: CompanyUsersIcon,
      breadcrumbs: false
    },
    // {
    //   id: "other-report",
    //   title: "Company Requests",
    //   type: "item",
    //   url: OTHER_REPORT_FORM_ROUTE,
    //   icon: CompanyUsersIcon,
    //   breadcrumbs: false
    // }
  ],
};

export default feedback;
