import React from "react";

function Icon({ width = "24", height = "24", fill = "#3d4853" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M11.977 2c-5.512 0-9.98 4.477-9.98 10s4.468 10 9.98 10c5.513 0 9.982-4.477 9.982-10S17.49 2 11.977 2zm0 2c4.41 0 7.985 3.582 7.985 8a7.964 7.964 0 01-1.279 4.33l-.768-.596C16.366 14.537 13.511 14 11.977 14c-1.534 0-4.39.537-5.938 1.734l-.768.598A7.965 7.965 0 013.992 12c0-4.418 3.576-8 7.985-8zm0 1.75a3.25 3.25 0 000 6.5 3.25 3.25 0 000-6.5zm0 2a1.25 1.25 0 010 2.5 1.25 1.25 0 010-2.5zm0 8.25c3.095 0 4.76 1.169 5.539 1.754A7.944 7.944 0 0111.977 20a7.944 7.944 0 01-5.538-2.246C7.219 17.169 8.882 16 11.977 16zm-5.934 1.34zm11.835.035c-.11.12-.218.242-.335.355.116-.113.225-.235.335-.355z"
      ></path>
    </svg>
  );
}

export default Icon;
