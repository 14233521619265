import * as Yup from "yup";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

//ui
import {
  Button,
  CardActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";
import Loader from "ui-component/Loader";
import MainCard from "ui-component/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

//project imports
import MSG from "constants/validation.message";
import { EDIT_PAGE } from "schemas/mutation";
import { GET_PAGE } from "schemas/query";
import { ValidationErrorMessage } from "./EditPageWrapper";

//redux
import useEditorPermission from "hooks/useEditorPermission";
import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch } from "store/hooks";

const validationSchema = Yup.object({
  title: Yup.string().required(MSG.TITLE_REQUIRED),
  content: Yup.string().required(MSG.CONTENT_REQUIRED),
});

interface EditPageProps {
  title: string;
  content: string;
}

const EditPage = () => {
  const theme = useTheme();
  const { slug } = useParams();
  const dispatch = useAppDispatch();

  const editorPermission = useEditorPermission();

  const { loading, data: getPages, refetch } = useQuery(GET_PAGE, {
    variables: {
      body: {
        slug,
      },
    },
    onError: (error) => {
      dispatch(
        snackbarOpen({ open: true, severity: "error", message: error.message })
      );
    },
  });

  const [editPage, { loading: editLoading }] = useMutation(EDIT_PAGE);

  const handleEditPage = async (values: EditPageProps) => {
    try {
      const { data } = await editPage({
        variables: {
          body: {
            content: values.content,
            id: getPages?.getPage?._id,
            slug,
            title: values.title,
          },
        },
      });

      dispatch(
        snackbarOpen({
          open: true,
          severity: "success",
          message: data?.updatePage?.status,
        })
      );

      refetch();
    } catch (error: any) {
      dispatch(
        snackbarOpen({ open: true, severity: "error", message: error.message })
      );
    }
  };

  if (loading || editLoading) {
    return <Loader />;
  }

  return (
    <MainCard content={false}>
      <Formik
        initialValues={{
          title: getPages?.getPage?.title || "",
          content: getPages?.getPage?.content || "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await handleEditPage(values);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={1} sx={{ padding: "30px 27px 11px" }}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      sx={{ ...theme.typography.customInput }}
                      color="secondary"
                    >
                      <InputLabel htmlFor="outlined-adornment-page-title">
                        Page title
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        //label="Page title"
                        id="title"
                        //size="medium"
                        inputProps={{
                          readOnly: !editorPermission,
                        }}
                        {...formik.getFieldProps("title")}
                      />
                      {formik.touched.title && formik.errors.title ? (
                        <ValidationErrorMessage
                          color={theme.palette.error.dark}
                        >
                          <InfoTwoToneIcon
                            sx={{ fontSize: "12px", mr: ".25rem" }}
                          />
                          {formik.errors.title}
                        </ValidationErrorMessage>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <FormControl
                      fullWidth
                      sx={{ ...theme.typography.customInput }}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-page-content">
                        Page content
                      </InputLabel> */}
                      <OutlinedInput
                        fullWidth
                        id="content"
                        //label="Page content"
                        multiline
                        rows={10}
                        //size="medium"
                        inputProps={{
                          readOnly: !editorPermission,
                        }}
                        {...formik.getFieldProps("content")}
                        placeholder="Type page content"
                      />
                      {formik.touched.content && formik.errors.content ? (
                        <ValidationErrorMessage
                          color={theme.palette.error.dark}
                        >
                          <InfoTwoToneIcon
                            sx={{ fontSize: "12px", mr: ".25rem" }}
                          />
                          {formik.errors.content}
                        </ValidationErrorMessage>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: `${theme.palette.warning.light}` }} />
            <CardActions>
              {editorPermission && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Stack direction="row">
                      <AnimateButton>
                        <Button
                          variant="contained"
                          sx={{ borderRadius: "4px", ml: "1.25rem" }}
                          type="submit"
                          size="medium"
                          color="primary"
                        >
                          <Typography>Save changes</Typography>
                        </Button>
                      </AnimateButton>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </CardActions>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default EditPage;
