import client from "ApiClientProvider/helper";
import storage from "redux-persist/lib/storage";
import { AnyAction, Reducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// reducer import
import authReducer from "./slice/authSlice";
import adminReducer from "./slice/adminSlice";
import companyReducer from "./slice/companySlice";
import companyUserReducer from "./slice/companyUserSlice";
import contactReducer from "./slice/contactSlice";
import customizationReducer from "./customizationReducer";
import investorReducer from "./slice/investorSlice";
import permissionReducer from "./slice/permissionSlice";
import reportReducer from "./slice/reportSlice";
import snackbarReducer from "./slice/snackbarSlice";
import subscriptionReducer from "./slice/subscriptionSlice";
import toastReducer from "./slice/toastSlice";
import transactionHistoryReducer from "./slice/transactionHistorySlice";
import userReducer from "./slice/userSlice";
import otherReportReducer from "./slice/otherReportSlice";
import questionPromptReducer from "./slice/questionPromotSlice";
import nonCustomerSlice from "./slice/nonCustomerSlice";
import trendingCompanySlice from "./slice/trendingCompanySlice";
import surveyManagementSlice, { surveyManagement } from "./slice/surveyManagementSlice";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  company: companyReducer,
  companyUser: companyUserReducer,
  contact: contactReducer,
  customization: customizationReducer,
  investor: investorReducer,
  permission: permissionReducer,
  report: reportReducer,
  otherReport: otherReportReducer,
  snackbar: snackbarReducer,
  subscription: subscriptionReducer,
  toast: toastReducer,
  transactionHistory: transactionHistoryReducer,
  user: userReducer,
  questionPrompt: questionPromptReducer,
  nonCustomer: nonCustomerSlice,
  trendingCompanies:trendingCompanySlice,
  surveyManagement:surveyManagementSlice
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/logoutUser") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:root");
    client.resetStore();

    state = {} as RootState;
  }

  return reducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof reducer>;
