import { useRef, useEffect, useState } from "react";

import * as Yup from "yup";
import { Editor } from "@tinymce/tinymce-react";
import { Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

//ui
import AnimateButton from "ui-component/extended/AnimateButton";
import Loader from "ui-component/Loader";
import MainCard from "ui-component/cards/MainCard";
import {
  Button,
  Grid,
  Stack,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Divider,
  CardActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

//redux
import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch } from "store/hooks";

//project imports
import MSG from "constants/validation.message";
import useEditorPermission from "hooks/useEditorPermission";
import { EDIT_EMAIL_TEMPLATE } from "schemas/mutation";
import { GET_EMAIL_TEMPLATE } from "schemas/query";
import { StyledError } from "styles/globalStyles";

interface ValueProps {
  subject: string;
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required(MSG.TEMPLATE_NAME_REQUIRED),
  subject: Yup.string().required(MSG.SUBJECT_LINE_REQUIRED),
});

const EditEmailTemplate = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  const editorRef = useRef<any>(null);
  const log = () => {
    if (editorRef.current) {
      setContent(editorRef.current?.getContent());
    }
  };

  const { slug } = useParams();
  const editorPermission = useEditorPermission();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { loading: contentLoading, data, refetch, error } = useQuery(
    GET_EMAIL_TEMPLATE,
    {
      variables: {
        body: {
          slug,
        },
      },
    }
  );

  const [editEmailTemplate, { loading: editLoading }] = useMutation(
    EDIT_EMAIL_TEMPLATE
  );

  const handleSubmit = async (values: ValueProps) => {
    try {
      const { data } = await editEmailTemplate({
        variables: {
          body: {
            content,
            slug,
            subject: values?.subject,
            name: values?.name,
          },
        },
      });

      refetch();

      data &&
        dispatch(
          snackbarOpen({
            open: true,
            severity: "success",
            message: data?.editEmailTemplate.status,
          })
        );
    } catch (error: any) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: error.message,
        })
      );
    }
  };

  useEffect(() => {
    if (data) {
      setContent(data.getEmailTemplate.content);
    }

    if (error) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: error.message,
        })
      );
    }
  }, [data, error, dispatch]);

  if (editLoading || contentLoading) {
    return <Loader />;
  }

  return (
    <>
      {loading && <Loader />}
      <MainCard content={false}>
        <Grid container rowSpacing={0} sx={{ padding: "30px 27px 11px" }}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                name: data?.getEmailTemplate?.name,
                subject: data?.getEmailTemplate?.subject,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} id="edit-email-template">
                  <Grid container rowSpacing={0}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            fullWidth
                            sx={{ ...theme.typography.customInput }}
                            color="secondary"
                          >
                            <InputLabel htmlFor="outlined-adornment-template-name">
                              Template name
                            </InputLabel>
                            <OutlinedInput
                              fullWidth
                              placeholder="Template name"
                              id="name"
                              size="medium"
                              {...formik.getFieldProps("name")}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <StyledError msgColor={theme.palette.error.dark}>
                                {formik.errors.name}
                              </StyledError>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            fullWidth
                            sx={{ ...theme.typography.customInput }}
                            color="secondary"
                          >
                            <InputLabel htmlFor="outlined-adornment-subject-line">
                              Subject line
                            </InputLabel>
                            <OutlinedInput
                              fullWidth
                              placeholder="Subject line"
                              id="subject"
                              size="medium"
                              {...formik.getFieldProps("subject")}
                            />

                            {formik.touched.subject && formik.errors.subject ? (
                              <StyledError msgColor={theme.palette.error.dark}>
                                {formik.errors.subject}
                              </StyledError>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12} md={7} sx={{ mb: "6rem" }}>
            <Editor
              tinymceScriptSrc={"/tinymce/tinymce.min.js"}
              onInit={(evt, editor) => {
                setLoading(false);
                editorRef.current = editor;
              }}
              initialValue={data?.getEmailTemplate?.content}
              init={{
                entity_encoding: "raw",
                verify_html: false,
                height: 488,

                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  " blocks | bold italic strikethrough forecolor backcolor blockquote   | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | code | preview",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:16px; }",
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: `${theme.palette.warning.light}` }} />
        <CardActions>
          {editorPermission && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row">
                  {/* <AnimateButton>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "4px", mr: ".25rem" }}
                    type="button"
                    size="medium"
                    color="primary"
                  >
                    <Typography>Preview</Typography>
                  </Button>
                </AnimateButton> */}
                  <AnimateButton>
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{ borderRadius: "4px", ml: "1.25rem" }}
                      type="submit"
                      onClick={log}
                      form="edit-email-template"
                    >
                      <Typography>Save changes</Typography>
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          )}
        </CardActions>
      </MainCard>
    </>
  );
};

export default EditEmailTemplate;
