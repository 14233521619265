import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company, CompanyItem } from "views/companies/company.types";

export interface Sort {
  field: string | null;
  order: string | null;
}

interface InitialState {
  searchText: string;
  page: number;
  limit: number;
  sort: {
    field: string | null;
    order: string | null;
  };

  singleCompany: Company;
  companyFilter: string | null;
  companyList: CompanyItem[];
}

const initialState: InitialState = {
  searchText: "",
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },

  singleCompany: {} as Company,
  companyFilter: "all",
  companyList: [],
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setSingleCompanyData: (state, action: PayloadAction<Company>) => {
      state.singleCompany = action.payload;
    },
    setCompanyFilter: (state, action: PayloadAction<string>) => {
      state.companyFilter = action.payload;
    },

    setCompanyList: (state, action: PayloadAction<CompanyItem[]>) => {
      state.companyList = action.payload;
    },
    resetCompany: () => initialState,
  },
});

export const {
  setSort,
  setLimit,
  setPage,
  setSearchText,
  setCompanyFilter,
  setSingleCompanyData,
  setCompanyList,
  resetCompany,
} = companySlice.actions;

export const selectCompany = (state: any) => state.company;

export default companySlice.reducer;
