import React from "react";

function Icon({ width = "24", height = "24", fill = "#3d4853" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M5.989 2a1.998 1.998 0 00-1.996 2v16c0 1.105.893 2 1.996 2h11.977a1.998 1.998 0 001.997-2V4c0-1.105-.894-2-1.997-2H5.99zm0 2h11.977v16H5.99V4zm1.996 3.063v2h7.985v-2H7.985zm0 3.937v2h7.985v-2H7.985zm0 4.063v2h7.985v-2H7.985z"
      ></path>
    </svg>
  );
}

export default Icon;