import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CompanySubscription } from "views/subscription/subscription.types";

export interface Sort {
  field: string | null;
  order: string | null;
}

interface InitialState {
  searchText: string;
  page: number;
  limit: number;
  sort: {
    field: string | null;
    order: string | null;
  };

  subscriptionFilter: string | null;
  subscriptionList: CompanySubscription[];
}

const initialState: InitialState = {
  searchText: "",
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },

  subscriptionFilter: "all",
  subscriptionList: [],
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setSubscriptionFilter: (state, action: PayloadAction<string>) => {
      state.subscriptionFilter = action.payload;
    },

    setSubscriptionList: (
      state,
      action: PayloadAction<CompanySubscription[]>
    ) => {
      state.subscriptionList = action.payload;
    },

    resetSubscription: () => initialState,
  },
});

export const {
  setSort,
  setLimit,
  setPage,
  setSearchText,
  setSubscriptionFilter,
  setSubscriptionList,
  resetSubscription,
} = subscriptionSlice.actions;

export const selectSubscription = (state: any) => state.subscription;

export default subscriptionSlice.reducer;
