/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { CustomTypography } from "themes/types";

export default function themeTypography(
  theme: CustomTypography
): TypographyOptions {
  return {
    fontFamily: theme?.customization?.fontFamily!,
    h6: {
      fontWeight: 500,
      color: theme.heading!,
      fontSize: "0.75rem",
    },
    h5: {
      fontSize: "0.875rem",
      color: theme.heading!,
      fontWeight: 500,
    },
    h4: {
      fontSize: "1rem",
      color: theme.heading!,
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.25rem",
      color: theme.heading!,
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.5rem",
      color: theme.primaryDark!,
      fontWeight: 700,
    },
    h1: {
      fontSize: "2.125rem",
      color: theme.heading!,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color:
        theme?.customization?.navType === "dark"
          ? theme.heading!
          : theme.grey600!,
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 400,
      color: theme.darkTextSecondary!,
    },
    caption: {
      fontSize: "0.75rem",
      color: theme.darkTextSecondary!,
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.334em",
    },
    body2: {
      letterSpacing: "0em",
      fontWeight: 400,
      lineHeight: "1.5em",
      color: theme.darkTextPrimary!,
    },
    button: {
      textTransform: "none",
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      // "& > label": {
      //   top: 24,
      //   left: 2,
      //   color: theme.grey600!,
      //   //color: 'green',
      //   '&[data-shrink="false"]': {
      //     top: 5,
      //   },
      // },
      "& > div > input,& > div > textarea": {
        fontSize: "14px",
        lineHeight: 1.5,
        fontWeight: 500,
        padding: "33px 16px 12px !important",
        boxShadow: `0 0 0 40px ${theme.colors?.paper} inset !important`,
        //boxShadow: none;
        "&.MuiInputBase-inputAdornedStart": {
          padding: "33px 16px 12px 9px !important",
        },
      },
      "& > div > textarea": {
        padding: "12px 16px 12px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.colors?.primaryDark}!important`,
      },
      // "& > div.input-filled > input": {
      //   boxShadow: `0 0 0 40px ${theme.colors?.grey50} inset !important`,
      // },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    mainContent: {
      backgroundColor: theme.background!,
      width: "100%",
      minHeight: "100vh",
      //minHeight: "calc(100vh - 87px)",
      flexGrow: 1,
      padding: "0 20px 20px 20px",
      display: "flex",
      //marginTop: "87px",
      marginRight: "20px",
      //borderRadius: `${theme?.customization?.borderRadius}px`,
      "@media (max-width: 900px)": {
        padding: "0 16px 16px!important",
      },
      "@media (max-width: 600px)": {
        marginLeft: "0!important",
        marginRight: "0!important",
        ">.MuiGrid-container": {
          width: "100%!important",
          marginLeft: "0!important",
          ">.MuiGrid-item": {
            padding: "16px!important",
          },
        },
      },
    },
    menuCaption: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: theme.heading!,
      padding: "6px 0",
      textTransform: "capitalize",
      margin: "0 0 12px",
    },
    subMenuCaption: {
      fontSize: "0.6875rem",
      fontWeight: 500,
      color: theme.darkTextSecondary!,
      textTransform: "capitalize",
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px",
    },
    smallAvatar: {
      width: "22px",
      height: "22px",
      fontSize: "1rem",
    },
    mediumAvatar: {
      width: "34px",
      height: "34px",
      fontSize: "1.2rem",
    },
    largeAvatar: {
      width: "44px",
      height: "44px",
      fontSize: "1.5rem",
    },
  };
}
