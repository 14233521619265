import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Sort {
  field: string | null;
  order: string | null;
}

interface InitialState {
  searchText: string | null;
  page: number;
  limit: number;
  sort: {
    field: string | null;
    order: string | null;
  };
}

const initialState: InitialState = {
  searchText: null,
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },
};

export const questionPromptSlice = createSlice({
  name: "questionPrompt",
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },
  },
});

export const {
  setSearchText,
  setPage,
  setLimit,
  setSort,
} = questionPromptSlice.actions;

export const selectQuestionPrompt = (state: any) => state.questionPrompt;

export default questionPromptSlice.reducer;
