import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Item {
  userName: string;
  email: string;
  jobTitle: string;
  companyName: string;
}

interface Sort {
  field: string | null;
  order: string | null;
}

interface InitialState {
  page: number;
  limit: number;
  searchText: string | null;
  sort: Sort;
  filterCondition: string | null;
  companyUserList: Item[];
}

const initialState: InitialState = {
  page: 1,
  limit: 10,
  searchText: null,
  sort: {
    field: null,
    order: null,
  },
  filterCondition: "all",
  companyUserList: [],
};

export const companyUserDataSlice = createSlice({
  name: "companyUser",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setCompanyUserList: (state, action: PayloadAction<Item[]>) => {
      state.companyUserList = action.payload;
    },

    setFilterCondition: (state, action: PayloadAction<string>) => {
      state.filterCondition = action.payload;
    },

    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },
    resetCompanyUser: () => initialState,
  },
});

export const {
  resetCompanyUser,
  setCompanyUserList,
  setFilterCondition,
  setLimit,
  setPage,
  setSearchText,
  setSort,
} = companyUserDataSlice.actions;

export const selectCompanyUser = (state: any) => state.companyUser;

export default companyUserDataSlice.reducer;
