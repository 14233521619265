import {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useEffect,
  useState
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU } from "store/actions";
import { DefaultRootStateProps, LinkTarget, NavItemType } from "types";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useAppDispatch } from "store/hooks";
import { resetInvestor } from "store/slice/investorSlice";
import { resetCompanyUser } from "store/slice/companyUserSlice";
import { resetCompany } from "store/slice/companySlice";
import { INVESTOR_ROUTE, SUBSCRIPTION_LIST_ROUTE } from "constants/routes";
import { resetSubscription } from "store/slice/subscriptionSlice";
import { GET_TOTAL_UNREAD } from "schemas/query";
import { useLazyQuery } from "@apollo/client";

export interface NavItemProps {
  item: NavItemType;
  level: number;
}


// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemProps) => {

  const location = useLocation();

  const [totalUnreadContact, setTotalUnreadContact] = useState(0);
  const [totalUnreadReport, setTotalUnreadReport] = useState(0);
  const [totalUnreadOtherReport, setTotalUnreadOtherReport] = useState(0);
  const [contactChip, setContactChip] = useState(false);
  const [otherReportChip, setOtherReportChip] = useState(false);
  const [reportChip, setReportChip] = useState(false);


  const theme = useTheme();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customization = useSelector(
    (state: DefaultRootStateProps) => state.customization
  );
  let [getTotalUnread] = useLazyQuery(GET_TOTAL_UNREAD, {
    onCompleted: result => {
      if (item.id && item.id === 'contact') {
        setTotalUnreadContact(result.getTotalUnreadContact.total);
        if (result.getTotalUnreadContact.total) {
          setContactChip(true);
        } else {
          setContactChip(false);
        }
      }
      if (item.id && item.id === 'report') {
        setTotalUnreadReport(result.getTotalUnreadIssue.total);
        if (result.getTotalUnreadIssue.total) {
          setReportChip(true);
        } else {
          setReportChip(false);
        }
      }
      if (item.id && item.id === 'other-report') {
        setTotalUnreadOtherReport(result.getTotalUnreadIssue.totalOther);
        if (result.getTotalUnreadIssue.totalOther) {
          setOtherReportChip(true);
        } else {
          setOtherReportChip(false);
        }
      }
    }
  });

  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const Icon = item?.icon!;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: {
    component:
    | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
    | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url!} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string) => {
    dispatch({ type: MENU_OPEN, id });
    // reset slices into initial state
    if (location.pathname.includes(INVESTOR_ROUTE)) {
      appDispatch(resetInvestor());
      return;
    }

    if (location.pathname === SUBSCRIPTION_LIST_ROUTE) {
      appDispatch(resetSubscription());
      return;
    }
    appDispatch(resetCompany());
    appDispatch(resetCompanyUser());
    matchesSM && dispatch({ type: SET_MENU, opened: false });
  };


  // active menu item on page load
  useEffect(() => {
    setTimeout(() => {
      getTotalUnread();
    }, 500);
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
  }, [location]);

  const activePage = (item: NavItemType) => {
    if (item.url && location.pathname.includes(item.url)) {
      return "active";
    }
  }


  return (
    <MenuItem>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={() => itemHandler(item.id!)}
        className={activePage(item)}
      >
        <ListItemIcon sx={{ minWidth: !item?.icon ? 18 : 36 }}>
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={<Typography>{item.title}</Typography>}
          secondary={
            item.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {item.caption}
              </Typography>
            )
          }
        />

        {contactChip && item.id === 'contact' && totalUnreadContact && (
          <Chip
            size="small"
            label={totalUnreadContact}
            sx={{ backgroundColor: theme.palette.error.dark, color: "white" }}
          />
        )}

        {reportChip && item.id === 'report' && totalUnreadReport && (
          <Chip
            size="small"
            label={totalUnreadReport}
            sx={{ backgroundColor: theme.palette.error.dark, color: "white" }}
          />
        )}
        {otherReportChip && item.id === 'other-report' && totalUnreadOtherReport && (
          <Chip
            size="small"
            label={totalUnreadOtherReport}
            sx={{ backgroundColor: theme.palette.error.dark, color: "white" }}
          />
        )}

      </ListItemButton>
    </MenuItem>
  );
};

export default NavItem;
