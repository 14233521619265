// material-ui
import { LinearProgressProps } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import loader from "assets/images/loader.gif";
// styles
const LoaderWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  zIndex: 999,
  width: "100%",
  position: "fixed",
  left: "0",
  top: "0",
});

// ==============================|| LOADER ||============================== //

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
  <LoaderWrapper>
    <img src={loader} alt="Loader" />
  </LoaderWrapper>
);

export default Loader;
