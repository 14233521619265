import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation LoginAdmin($body: LoginAdminInput!) {
    loginAdmin(body: $body) {
      userData {
        accesstokenAndExpiry {
          accessTokenExpiresIn
          accesstoken
        }
        admin {
          _id
          digitalPicture
          email
          phoneNumber
          username
          permission
        }
        refreshtokenAndExpiry {
          refreshTokenExpiresIn
          refreshtoken
        }
      }
      otpData {
        message
        otpExpiresAt
      }
    }
  }
`;

export const SEND_PASSWORD_RESET_LINK = gql`
  mutation forgotPassword($body: EmailInput!) {
    forgotPassword(body: $body) {
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($body: ResetPasswordInput!) {
    resetPassword(body: $body) {
      status
    }
  }
`;

export const EDIT_PAGE = gql`
  mutation updatePage($body: UpdatePageInput!) {
    updatePage(body: $body) {
      status
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($body: CreateAdminInput!) {
    createAdmin(body: $body) {
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($body: ChangePasswordInput!) {
    changePassword(body: $body) {
      status
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($body: UpdateProfileInput!) {
    updateProfile(body: $body) {
      status
      updatedAdmin {
        phoneNumber
        username
        digitalPicture
        email
        permission
      }
    }
  }
`;

export const EDIT_ADMIN = gql`
  mutation EditProfile($body: EditAdminInput!) {
    editProfile(body: $body) {
      status
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($body: AdminIdInput!) {
    deleteAdmin(body: $body) {
      status
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation getUploadUrl($body: GetUploadUrlInput!) {
    getUploadUrl(body: $body) {
      uploadURL
      Key
    }
  }
`;

export const EDIT_COMPANY_USER = gql`
  mutation editCompanyUser($body: EditCompanyUserInput!) {
    editCompanyUser(body: $body) {
      status
    }
  }
`;

export const UPDATE_NON_CUSTOMER = gql`
  mutation UpdateNonCustomerDetail($body: NonCustomerDetailInput!) {
    updateNonCustomerDetail(body: $body) {
      data {
        about
        websiteUrl
        legalBusinessName
        abn
        asxCode
        companyLogo
      }
      message
    }
  }
`;

export const EDIT_EMAIL_TEMPLATE = gql`
  mutation editEmailTemplate($body: CreateEmailTemplateInput!) {
    editEmailTemplate(body: $body) {
      status
    }
  }
`;

export const EDIT_INVESTOR = gql`
  mutation editInvestor($body: EditInvestorInput!) {
    editInvestor(body: $body) {
      status
    }
  }
`;

export const DELETE_INVESTOR = gql`
  mutation removeInvestor($body: InvestorIdInput!) {
    removeInvestor(body: $body) {
      status
    }
  }
`;

export const EDIT_COMPANY = gql`
  mutation editCompany($body: EditCompanyInput!) {
    editCompany(body: $body) {
      status
    }
  }
`;

export const VERIFY_COMPANY = gql`
  mutation verifyCompany($body: CompanyVerifyInput!) {
    verifyCompany(body: $body) {
      status
    }
  }
`;

export const VERIFY_COMPANY_V2 = gql`
  mutation VerifyCompanyV2($body: CompanyVerifyInputV2!) {
    verifyCompanyV2(body: $body) {
      status
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation removeCompany($body: CompanyIdInput!) {
    removeCompany(body: $body) {
      status
    }
  }
`;

export const SAVE_QUESTION_PROMPT = gql`
  mutation CreateMonthlyQuestionPrompt($body: MonthlyQuestionPromptInput!) {
    createMonthlyQuestionPrompt(body: $body) {
      status
    }
  }
`;

export const CHANGE_EMAIL_ADDRESS = gql`
  mutation ChangeEmailAddress($changeEmailAddressInput: ChangeEmailInput!) {
    changeEmailAddress(changeEmailAddressInput: $changeEmailAddressInput) {
      message
      otpExpiresAt
    }
  }
`;

export const VERIFY_EMAIL_OTP = gql`
  mutation VerifyEmailOtp($verifyEmailOtpInput: VerifyEmailOtpInput!) {
    verifyEmailOtp(verifyEmailOtpInput: $verifyEmailOtpInput) {
      userData {
        token {
          accessToken
          accessTokenExpiresIn
          refreshToken
          refreshTokenExpiresIn
        }
        user {
          _id
          digitalPicture
          email
          phoneNumber
          username
          permission
        }
      }
    }
  }
`;

export const SEND_EMAIL_OTP = gql`
  mutation SendEmailOtp($sendEmailOtpInput: SendEmailOtpInput!) {
    sendEmailOtp(sendEmailOtpInput: $sendEmailOtpInput) {
      message
      otpExpiresAt
    }
  }
`;

export const CHANGE_TOGGLE_VISIBILITY = gql`
  mutation Mutation($body: CompanyIdInput!) {
    updateTrendingCompanyToggleVerifyInput(body: $body) {
      isToggleVisibility
    }
  }
`;

/**ADD SURVEY QUESTION */
export const ADD_SURVEY_QUESTION = gql`
  mutation CreateSurvey($createSurveyInput: CreateSurveyResponseInput!) {
    createSurvey(createSurveyInput: $createSurveyInput) {
      _id
      createdBy
      archivedBy
      isArchived
      question
      response
    }
  }
`;

export const ACTIVATE_SURVEY_QUESTION = gql`
  mutation ActivateSurvey($activeSurveyInput: ActivateSurveyInput!) {
    activateSurvey(activeSurveyInput: $activeSurveyInput) {
      _id
      activatedBy
      activationEndDate
      activationStartDate
      isActive
      isActiveAllTime
      survey
      createdAt
    }
  }
`;
export const DEACTIVATE_SURVEY_QUESTION = gql`
  mutation DeactivateSurvey($deActiveSurveyInput: DeactivateSurveyInput!) {
    deactivateSurvey(deActiveSurveyInput: $deActiveSurveyInput) {
      activatedBy
      _id
      activationStartDate
      activationEndDate
      isActive
      isActiveAllTime
      survey
    }
  }
`;

export const FLAG_AS_TEST_COMPANY = gql`
  mutation FlagTestCompany($companyId: String!, $value: Boolean!) {
    flagTestCompany(companyId: $companyId, value: $value) {
      status
    }
  }
`;
