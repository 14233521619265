import React from "react";
import { useTable } from "react-table";

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Stack,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ButtonWithIcon from "ui-component/extended/ButtonWithIcon";
import { DateTime } from "luxon";
import { StyledBarLoader } from "styles/globalStyles";
import { PAGE_MANAGEMENT_ROUTE } from "constants/routes";

const ActionsComponent = ({ slug }: { slug: string }) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <ButtonWithIcon
        icon={<EditIcon />}
        clickHandler={() => navigate(`${PAGE_MANAGEMENT_ROUTE}/${slug}`)}
      />
    </Stack>
  );
};

const ReactTable = ({
  data: pageData,
  pageColumns,
  loading,
  className,
}: any) => {
  // format date
  const formatDate = (date: string) => {
    return DateTime.fromISO(date)
      .toFormat("f")
      .toLocaleLowerCase()
      .replace(",", " ");
  };

  const data = React.useMemo(
    () =>
      pageData?.map((page: any, index: number) => {
        return {
          ...page,
          createdAt: formatDate(page.createdAt),
          index: `0${index + 1}`,
          actions: <ActionsComponent slug={page.slug} />,
        };
      }),
    [pageData]
  );

  const columns = React.useMemo(() => pageColumns, [pageColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <>
      <StyledBarLoader>
        {loading && <LinearProgress color="inherit" />}
      </StyledBarLoader>
      <TableContainer className="table-pages">
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()} align="left">
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()} align="left">
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReactTable;
