import { useNavigate } from "react-router-dom";

// project imports
import { GuardProps } from "types";
import { useEffect } from "react";

import { useAppSelector } from "store/hooks";
import CONST from "constants/generalConstants";
import { ERROR_ROUTE, LOGIN_ROUTE } from "constants/routes";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const EditGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, permission } = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(LOGIN_ROUTE, { replace: true });
    }

    if (permission !== CONST.SUPER && permission !== CONST.EDITOR) {
      navigate(ERROR_ROUTE);
    }
  }, [isLoggedIn, navigate, permission]);

  return children;
};

export default EditGuard;
