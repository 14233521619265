import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrendingCompany } from "views/companies/company.types";

export interface Sort {
  field: string | null;
  order: string | null;
}

interface InitialState {
  searchText: string;
  page: number;
  limit: number;
  sort: {
    field: string | null;
    order: string | null;
  };

  singleCompany: TrendingCompany;
  companyFilter: string | null;
  companyList: TrendingCompany[];
  top5Companies: TrendingCompany[];
  reload: boolean;
}

const initialState: InitialState = {
  searchText: "",
  page: 1,
  limit: 10,
  sort: {
    field: null,
    order: null,
  },

  singleCompany: {} as TrendingCompany,
  companyFilter: "all",
  companyList: [],
  top5Companies: [],
  reload: false
};

export const trendingCompanySlice = createSlice({
  name: "trendingCompanies",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },

    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setSingleTrendingCompanyData: (state, action: PayloadAction<TrendingCompany>) => {
      state.singleCompany = action.payload;
    },
    setTrendingCompanyFilter: (state, action: PayloadAction<string>) => {
      state.companyFilter = action.payload;
    },

    setTrendingCompanyList: (state, action: PayloadAction<TrendingCompany[]>) => {
      state.companyList = action.payload;
    },
    setReload: (state, action: PayloadAction<boolean>) => {
      state.reload = action.payload;
    },
    resetTrendingCompany: () => initialState,

  },
});

export const {
  setSort,
  setLimit,
  setPage,
  setSearchText,
  setTrendingCompanyFilter,
  setSingleTrendingCompanyData,
  setTrendingCompanyList,
  setReload,
  resetTrendingCompany,

} = trendingCompanySlice.actions;

export const selectTrendingCompany = (state: any) => state.trendingCompanies;

export default trendingCompanySlice.reducer;
