const generalConstants = {
  EN: {
    EDITOR: "editor",
    VIEWER: "viewer",
    SUPER: "super",
    CHANGE_PASSWORD: "Change password",
    USER_PROFILE: "User profile",
    ADMIN_SORT_OPTIONS: ["A-Z", "Z-A"],
    ASCENDING: "ASC",
    DESCENDING: "DESC",
    SUPPORTED_PROFILE_FORMATS: ["image/jpg", "image/jpeg", "image/png"],
    PHONE_REGEX:
      "/^(?:+?(61))? ?(?:((?=.*)))?(0?[2-57-8]))? ?(dd(?:[- ](?=d{3})|(?!dd[- ]?d[- ]))dd[- ]?d[- ]?d{3})$/",
    DASH: "-",
    ACTIVE: "active",
    INACTIVE: "inactive",
    VERIFIED: "verified",
    UNVERIFIED: "unverified",
    ALL: "all",
    INVESTOR: "investor",
    NIL: "0",
    ZERO: "0",
    NILL:'Nil'
  },
};

const lang = process.env.SYSTEM_LANGUAGE || "EN";

let CONST: any;

if (lang === "EN") {
  CONST = generalConstants.EN;
}

export default CONST;
