
function TrendingIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M12.2871 15.0432C12.4746 15.2307 12.7289 15.336 12.9941 15.336C13.2592 15.336 13.5135 15.2307 13.7011 15.0432L16.9941 11.7502L17.7011 11.0432C17.7966 10.9509 17.8728 10.8406 17.9252 10.7186C17.9776 10.5966 18.0052 10.4654 18.0063 10.3326C18.0075 10.1998 17.9822 10.0681 17.9319 9.94523C17.8816 9.82233 17.8073 9.71068 17.7135 9.61679C17.6196 9.52289 17.5079 9.44864 17.385 9.39836C17.2621 9.34808 17.1304 9.32278 16.9977 9.32393C16.8649 9.32509 16.7337 9.35267 16.6117 9.40508C16.4897 9.45749 16.3793 9.53367 16.2871 9.62918L15.5801 10.3362L12.9941 12.9222L10.7011 10.6292C10.5135 10.4417 10.2592 10.3364 9.99406 10.3364C9.7289 10.3364 9.47459 10.4417 9.28706 10.6292L5.28706 14.6292C5.1049 14.8178 5.00411 15.0704 5.00639 15.3326C5.00866 15.5948 5.11383 15.8456 5.29924 16.031C5.48465 16.2164 5.73546 16.3216 5.99766 16.3239C6.25986 16.3261 6.51246 16.2253 6.70106 16.0432L9.99406 12.7502L12.2871 15.0432Z" fill="#56CCF2"/>
  <path d="M16.9941 11.7502V13.3362C16.9941 13.6014 17.0994 13.8558 17.287 14.0433C17.4745 14.2308 17.7288 14.3362 17.9941 14.3362C18.2593 14.3362 18.5136 14.2308 18.7012 14.0433C18.8887 13.8558 18.9941 13.6014 18.9941 13.3362V9.33618C18.9941 9.07097 18.8887 8.81661 18.7012 8.62908C18.5136 8.44154 18.2593 8.33618 17.9941 8.33618H13.9941C13.7288 8.33618 13.4745 8.44154 13.287 8.62908C13.0994 8.81661 12.9941 9.07097 12.9941 9.33618C12.9941 9.6014 13.0994 9.85575 13.287 10.0433C13.4745 10.2308 13.7288 10.3362 13.9941 10.3362H15.5801L16.2871 9.62918C16.3793 9.53367 16.4897 9.45749 16.6117 9.40508C16.7337 9.35267 16.8649 9.32509 16.9977 9.32393C17.1304 9.32278 17.2621 9.34808 17.385 9.39836C17.5079 9.44864 17.6196 9.52289 17.7135 9.61679C17.8073 9.71068 17.8816 9.82233 17.9319 9.94523C17.9822 10.0681 18.0075 10.1998 18.0063 10.3326C18.0052 10.4654 17.9776 10.5966 17.9252 10.7186C17.8728 10.8406 17.7966 10.9509 17.7011 11.0432L16.9941 11.7502Z" fill="#56CCF2"/>
</svg>
    
  );
}

export default TrendingIcon;
