import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AUTH_PAGE_ENUM } from "enums/auth";

interface InitialStateProps {
  authPage: string;
}

const initialState: InitialStateProps = {
  authPage: AUTH_PAGE_ENUM.LOGIN,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthPage: (state, action: PayloadAction<string>) => {
      state.authPage = action.payload;
    },
  },
});

// reducers
export const { setAuthPage } = authSlice.actions;

// selectors
export const selectAuthPage = (state: any) => state.auth.authPage;

export default authSlice.reducer;
