import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { DefaultRootStateProps } from "types";
import User1 from "assets/images/users/user-round.svg";

// assets
import UserIcon from "ui-component/Icons/UserIcon";
import LogoutIcon from "ui-component/Icons/LogoutIcon";

// redux
import { useAppDispatch, useAppSelector } from "store/hooks";
import { logoutUser, setProfileUrl } from "store/slice/userSlice";
import { removeTokens } from "./helper";
import { ACCOUNT_PROFILE_ROUTE } from "constants/routes";
import { UserName } from "./profileSection.styles";
import { useQuery } from "@apollo/client";
import { GET_PROFILE_URL } from "schemas/query";
import Loader from "ui-component/Loader";
import { snackbarOpen } from "store/slice/snackbarSlice";
import { SOMETHING_WENT_WRONG } from "constants/messages";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector(
    (state: DefaultRootStateProps) => state.customization
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { username, digitalPicture } = useAppSelector((state) => state.user);
  const firstName = username?.split(" ")[0];

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = React.useRef<any>(null);
  const handleLogout = async () => {
    try {
      if (window.confirm("Are you sure you want to logout?")) {
        dispatch(logoutUser());
        removeTokens();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const { data, loading, error } = useQuery(GET_PROFILE_URL);
  

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    route: string = ""
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (data) {
      dispatch(setProfileUrl(data.getProfileUrl?.profileUrl));
    }
    if (error) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        })
      );
    }
  }, [data, dispatch, error]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          border: "none",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          //marginTop: 1,
          // borderColor:
          //   theme.palette.mode === "dark"
          //     ? theme.palette.dark.main
          //     : theme.palette.primary.light,
          // backgroundColor:
          //   theme.palette.mode === "dark"
          //     ? theme.palette.dark.main
          //     : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            // borderColor: theme.palette.primary.main,
            // background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
            margin: 0,
            padding: 0,
            color: theme.palette.primary.dark,
          },
        }}
        icon={
          <Avatar
            src={digitalPicture || User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <UserName>
            Hi <span>{firstName}</span>
          </UserName>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 0],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          //backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 0}
                          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            handleListItemClick(event, 0, ACCOUNT_PROFILE_ROUTE)
                          }
                        >
                          <ListItemIcon>
                            <UserIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                Account settings
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={(event) => {
                            handleLogout();
                            handleClose(event);
                          }}
                        >
                          <ListItemIcon>
                            <LogoutIcon className="icon-stroke" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
