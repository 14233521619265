import { gql } from "@apollo/client";

export const GET_PAGES = gql`
  query GetPages {
    getPages {
      _id
      author
      slug
      content
      title
      updatedBy
      createdAt
    }
  }
`;

export const GET_PAGE = gql`
  query GetPage($body: PageSlugInput!) {
    getPage(body: $body) {
      _id
      author
      authorPermission
      content
      createdAt
      slug
      title
      updatedBy
    }
  }
`;

export const GET_ADMINS = gql`
  query GetAdmins($body: ListAdminsInput!) {
    listAdmins(body: $body) {
      items {
        _id
        email
        permission
        username
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_ADMIN_PROFILE = gql`
  query getProfile($body: AdminIdInput!) {
    getAdminProfile(body: $body) {
      _id
      digitalPicture
      email
      lastLoggedInAt
      permission
      phoneNumber
      username
      firstName
      lastName
    }
  }
`;

export const LIST_COMPANY_USER = gql`
  query listCompanyUsers($body: ListCompanyUserInput!) {
    listCompanyUsers(body: $body) {
      items {
        _id
        companyName
        email
        userName
        companyId
        profileImage
        jobTitle
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_COMPANY_USER = gql`
  query getCompanyUser($body: GetCompanyUserInput!) {
    getCompanyUser(body: $body) {
      email
      lastName
      firstName
      isAdmin
      isStaff
      siteInformation
      phoneNumber
      jobTitle
      profileImage
      companyName
    }
  }
`;

export const GET_TOTAL_COMPANIES = gql`
  query getTotalCompany {
    getTotalCompany {
      count
    }
  }
`;

export const GET_TOTAL_INVESTORS = gql`
  query getTotalInvestors {
    getTotalInvestors {
      count
    }
  }
`;

export const GET_EMAIL_TEMPLATES = gql`
  query {
    listEmailTemplates {
      _id
      createdAt
      name
      slug
      updatedAt
    }
  }
`;

export const GET_EMAIL_TEMPLATE = gql`
  query getEmailTemplate($body: MailSlugInput!) {
    getEmailTemplate(body: $body) {
      _id
      name
      content
      subject
    }
  }
`;

export const GET_INVESTORS = gql`
  query listInvestors($body: ListInvestorsInput!) {
    listInvestors(body: $body) {
      items {
        _id
        communicationConsent
        email
        enquiriesSubmitted
        startDate
        userName
        onBoardingQuestions {
          question
          responses
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_INVESTOR = gql`
  query getInvestor($body: InvestorIdInput!) {
    getInvestor(body: $body) {
      _id
      communicationConsent
      email
      mobileNum
      startDate
      userName
    }
  }
`;

export const LIST_COMPANY = gql`
  query GetCompanies($body: ListCompaniesInput!) {
    getCompanies(body: $body) {
      items {
        _id
        accountStatus
        email
        investorCount
        legalBusinessName
        questionCount
        respondedQuestionsPercentage
        satisfactionScorePercentage
        startDate
        totalAnnouncementViews
        totalRespondedQuestions
        verified
        averageResponseTime
        noOfInvestorsWhoAskedQuestions
        currentInvestorsCount
        totalCompanyViews
        qrDownloadCount
        totalGuestInvestorCount
        isFlaggedAsTestCompany
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_PROFILE_URL = gql`
  query getProfileUrl {
    getProfileUrl {
      profileUrl
    }
  }
`;
export const GET_FILE_URL = gql`
  query GetFileurl($key: String!) {
    getFileurl(key: $key) {
      profileUrl
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($body: CompanyIdInput!) {
    getCompany(body: $body) {
      _id
      email
      companyLogo
      phoneNumber
      addressLineOne
      addressLineTwo
      suburb
      state
      postCode
      about
      websiteUrl
      legalBusinessName
      abn
      optionalBusinessName
      verifiedAt
      asxCode
      isFlaggedAsTestCompany
      location {
        coordinates
      }
    }
  }
`;

export const GET_ADMINS_WITH_PICTURE = gql`
  query getAdminPictureWithCount {
    getAdminPictureWithCount {
      userCount
      users {
        _id
        digitalPicture
        username
      }
    }
  }
`;

export const GET_INVESTORS_WITH_PICTURE = gql`
  query getInvestorPictureWithCount {
    getInvestorPictureWithCount {
      userCount
      users {
        _id
        digitalPicture
        username
      }
    }
  }
`;

export const LIST_COMPANY_ADMINISTRATORS = gql`
  query listSpecificCompanyUser($body: CompanyIdInput!) {
    listSpecificCompanyUser(body: $body) {
      companyAdministrators {
        companyId
        email
        userId
        username
      }
    }
  }
`;

export const GET_ADMINS_INFO = gql`
  query getAdminsInfo {
    getAdminsInfo {
      email
      permission
      phoneNumber
      username
    }
  }
`;

export const GET_ISSUES = gql`
  query listIssues($body: ListIssueInput!) {
    listIssues(body: $body) {
      items {
        _id
        name
        title
        email
        userType
        dateSent
        hasRead
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_ISSUE_DETAIL = gql`
  query getIssueDetails($body: IssueIdInput!) {
    getIssueDetails(body: $body) {
      name
      dateSent
      description
      title
      attachment
    }
  }
`;

export const GetSignedUrlFromKey = gql`
  query getSignedUrlFromKey($body: KeyInput!) {
    getSignedUrlFromKey(body: $body) {
      url
    }
  }
`;

export const GET_CONTACTS = gql`
  query listContacts($body: ListContactsInput!) {
    listContacts(body: $body) {
      items {
        _id
        createdAt
        email
        username
        subject
        hasRead
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_CONTACT_DETAILS = gql`
  query getContactDetails($body: GetContactDetails!) {
    getContactDetails(body: $body) {
      _id
      createdAt
      message
      subject
      username
    }
  }
`;

export const LIST_COMPANY_SUBSCRIPTIONS = gql`
  query listCompanySubscriptions($body: ListCompanySubscriptionInput!) {
    listCompanySubscriptions(body: $body) {
      items {
        companyId
        email
        legalBusinessName
        subscriptionPlan
        subscriptionPaymentDate
        subscriptionStatus
        stripeCustomerId
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_COMPANY_SUBSCRIPTION = gql`
  query getCompanySubscription($body: CompanyIdInput!) {
    getCompanySubscription(body: $body) {
      _id
      plan
      status
      startDate
      endDate
      activationDate
      subscribed
    }
  }
`;

export const LIST_TRANSACTION_HISTORY = gql`
  query listSubscriptionTransactions($body: ListSubscriptionTransactionInput!) {
    listSubscriptionTransactions(body: $body) {
      items {
        _id
        amount
        invoicePdfUrl
        planName
        transactionId
        type
        date
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_COMPANY_ENGAGEMENT_LEVEL = gql`
  query getCompanyEngagementLevel($body: EngagementInput!) {
    getCompanyEngagementLevel(body: $body) {
      _id
      asxCode
      companyName
      engagementLevel
    }
  }
`;
export const GET_TOTAL_ENQUIRY = gql`
  query getEnquiryTotal($body: EnquiryTotalInput!) {
    getEnquiryTotal(body: $body) {
      totalEnquiry
    }
  }
`;
export const GET_TOTAL_UNREAD = gql`
  query GeneralQuery {
    getTotalUnreadContact {
      total
    }
    getTotalUnreadIssue {
      total
      totalAll
      totalOther
    }
  }
`;
export const GET_ALL_CONTACTS = gql`
  query ListAllContacts {
    listAllContacts {
      contactsList {
        _id
        createdAt
        email
        subject
        username
        message
      }
    }
  }
`;
export const GET_ALL_ISSUES = gql`
  query ListAllIssues($body: ListAllIssueInput!) {
    listAllIssues(body: $body) {
      issues {
        _id
        dateSent
        email
        name
        title
        userType
        description
      }
    }
  }
`;
export const GET_ACTIVE_USERS = gql`
  query GetActiveUsers($body: ActiveUserInput!) {
    getActiveUsers(body: $body) {
      countList
      labelList
    }
  }
`;
export const GET_DRAFT_QUESTION = gql`
  query GetDraftMonthlyQuestionPrompt {
    getDraftMonthlyQuestionPrompt {
      draftData {
        _id
        publishedDate
        question
        status
        totalCopiedTimes
      }
    }
  }
`;
export const GET_ALL_QUESTION_PROMPT = gql`
  query ListPublishedMonthlyQuestionPrompt(
    $body: ListMonthlyQuesitonPromptInput!
  ) {
    listPublishedMonthlyQuestionPrompt(body: $body) {
      items {
        _id
        publishedDate
        question
        status
        totalCopiedTimes
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export const GET_TOTAL_QUESTION = gql`
  query GetTotalQuestions {
    getTotalQuestions {
      count
    }
  }
`;

export const GET_ALL_INVESTORS = gql`
  query ListAllInvestors($body: ListAllInvestorsInput!) {
    listAllInvestors(body: $body) {
      items {
        _id
        communicationConsent
        email
        enquiriesSubmitted
        startDate
        userName
        onBoardingQuestions {
          question
          responses
        }
      }
    }
  }
`;

export const GET_TOTAL_NON_CUSTOMERS = gql`
  query GetTotalNonCustomers {
    getTotalNonCustomers {
      count
    }
  }
`;

export const GET__NON_CUSTOMER_DETAIL = gql`
  query GetNonCustomerDetail($body: NonCustomerDetail!) {
    getNonCustomerDetail(body: $body) {
      about
      websiteUrl
      legalBusinessName
      abn
      asxCode
      companyLogo
      key
    }
  }
`;

export const LIST_COMPANY_NON_USERS = gql`
  query GetNonCustomersList($body: ListCompaniesInput!) {
    getNonCustomersList(body: $body) {
      items {
        _id
        currentInvestorsCount
        investorCount
        potentialInvestorsCount
        legalBusinessName
        totalAnnouncementViews
        totalCompanyRequests
        totalCompanyViews
      }
      meta {
        currentPage
        itemsPerPage
        itemCount
        totalPages
        totalItems
      }
    }
  }
`;

export const LIST_TRENDING_COMPANIES = gql`
  query GetPaginatedTrendingCompanies($body: ListTrendingCompaniesInput!) {
    getPaginatedTrendingCompanies(body: $body) {
      items {
        adjustedScore
        _id
        asxCode
        companyLogo
        createdAt
        legalBusinessName
        size
        totalScore
        isToggleVisibility
        rank
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_TOP_FIVE_TRENDING_COMPANIES = gql`
  query GetTopFiveCompanies {
    getTopFiveCompanies {
      _id
      adjustedScore
      asxCode
      companyLogo
      createdAt
      isToggleVisibility
      legalBusinessName
      rank
      size
      totalScore
    }
  }
`;

/***Survey List Query */
export const LIST_SURVEYS = gql`
  query GetSurveyList($listSurveysInput: SurveyListInput!) {
    getSurveyList(listSurveysInput: $listSurveysInput) {
      items {
        _id
        currentActivatedSurveyId
        archivedBy
        currentActivationDate
        currentActivationEndDate
        isActive
        isArchived
        lastActivationDate
        question
        response
        totalResponses
        isActiveAllTime
        totalActiveSurveys
        updatedAt
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export const GET_COUNT_OF_THE_SURVEYS = gql`
  query GetSurveyCount {
    getSurveyCount {
      activeCount
      allCount
      archivedCount
      inactiveCount
    }
  }
`;
export const GET_RESULTS_OF_THE_SURVEYS = gql`
  query GetSurveyActivatedCompanyResponses(
    $surveyCurrentActivationInput: SurveyCurrentActivatedInput!
  ) {
    getSurveyActivatedCompanyResponses(
      surveyCurrentActivationInput: $surveyCurrentActivationInput
    ) {
      items {
        _id
        companyId
        companyName
        totalLikes
        totalResponses
        totalUnlikes
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_HISTORY_OF_THE_SURVEYS = gql`
  query GetSurveyActivatedHistoryCompanyResponses(
    $surveyHistoryInputId: String!
  ) {
    getSurveyActivatedHistoryCompanyResponses(
      surveyHistoryInputId: $surveyHistoryInputId
    ) {
      items {
        _id
        activationStartDate
        activationEndDate
        adminName
        totalResponses
      }
    }
  }
`;
