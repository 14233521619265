import React from "react";

function Icon({ width = "21", height = "18", fill = "#3d4853" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 21 18"
    >
      <path
        fill={fill}
        d="M2.992 0A2.013 2.013 0 00.996 2v16H20.96V6c0-1.093-.906-2-1.997-2H14.97V2c0-1.093-.905-2-1.996-2H2.992zm0 2h9.982v14H2.992V2zM4.99 4v2h1.996V4H4.989zM8.98 4v2h1.996V4H8.981zm5.989 2h3.992v10H14.97v-2h1.996v-2H14.97v-2h1.996V8H14.97V6zM4.989 8v2h1.996V8H4.989zM8.98 8v2h1.996V8H8.981zM4.99 12v2h1.996v-2H4.989zm3.992 0v2h1.996v-2H8.981z"
      ></path>
    </svg>
  );
}

export default Icon;
