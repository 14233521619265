import { Grid, Typography, Box } from "@mui/material";
import CustomAvatarGroup from "./CustomAvatarGroup";
import { useNavigate } from "react-router-dom";
import { ADMIN_LIST_ROUTE } from "constants/routes";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// TODO:Handle loading skeleton

interface Props {
  users: {
    _id: string;
    username: string;
    digitalPicture: string;
  }[];
  userCount: number;
  route: string;
}
const UserWithImage = ({ users, userCount, route }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleClick = () => {
    navigate(route);
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: theme.palette.primary.light,
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "20px 20px 18px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              color: theme.palette.secondary.main,
            }}
          >
            {route === ADMIN_LIST_ROUTE ? "Admin users" : "App users"}
          </Typography>
          <Box
            sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}
            onClick={handleClick}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: theme.palette.secondary.main,
              }}
            >
              All
            </Typography>
            <ArrowForwardIosIcon
              color="secondary"
              sx={{ fontSize: "11px", ml: ".5rem" }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", padding: "20px" }}>
          <CustomAvatarGroup users={users} userCount={userCount} />
        </Box>
      </Box>
    </Grid>
  );
};

export default UserWithImage;
